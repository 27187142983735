<template>
  <div>
    <van-nav-bar
        title="找不到页面"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onBack"
    />
    <van-empty description="找不到页面" />
  </div>
</template>

<script>
export default {
  name: "notfound",
  methods: {
    onBack : function (){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>